
import { defineComponent, inject } from "vue";
import { xxstate } from "../store/index";

export default defineComponent({
  name: "metabolism",
  data() {
    return {
      state: inject("state") as xxstate,
    };
  },
  computed: {
    daily_exercise(): string {
      return this.state.a.netkcal.toFixed(0);
    },
    current_lbm(): string {
      return this.state.display_weight(this.state.s.get_ffm(), true, 1);
    }
  },
  methods: {
  },
});
