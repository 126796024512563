import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bg-light px-5 py-3 rounded" }
const _hoisted_2 = {
  key: 0,
  class: "mb3 alert alert-success",
  role: "alert"
}
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = {
  key: 1,
  class: "alert alert-danger mb3",
  role: "alert"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "alert alert-danger mb3",
  role: "alert"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-6 col-12 mb-3" }
const _hoisted_9 = {
  class: "form-label",
  for: "firstname"
}
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "invalid-feedback" }
const _hoisted_12 = { class: "col-lg-6 col-12 mb-3" }
const _hoisted_13 = {
  class: "form-label",
  for: "lastname"
}
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "invalid-feedback" }
const _hoisted_16 = { class: "row mb-3" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = {
  class: "form-label",
  for: "email"
}
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "invalid-feedback" }
const _hoisted_21 = { class: "row mb-3" }
const _hoisted_22 = { class: "col-12" }
const _hoisted_23 = {
  class: "form-label",
  for: "username"
}
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { class: "invalid-feedback" }
const _hoisted_26 = { class: "col-12 text-muted fst-italic small" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-12 mb-3" }
const _hoisted_29 = { class: "form-check" }
const _hoisted_30 = {
  class: "form-check-label",
  for: "subscribe"
}
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col-12 mb-3" }
const _hoisted_33 = { class: "form-check" }
const _hoisted_34 = {
  class: "form-check-label",
  for: "agree"
}
const _hoisted_35 = { class: "invalid-feedback" }
const _hoisted_36 = { class: "row" }
const _hoisted_37 = { class: "d-grid mb-3" }
const _hoisted_38 = {
  class: "btn btn-primary btn-lg",
  type: "submit"
}
const _hoisted_39 = { class: "row mb-3" }
const _hoisted_40 = { class: "col-12" }
const _hoisted_41 = {
  href: "/u/sendpass",
  class: "small float-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.xlate('register-title')), 1),
    (_ctx.apirc && _ctx.apirc.status === 'OK')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.xlate('register-done')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.xlate('register-confirm')) + ". ", 1),
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("em", null, [
              _createElementVNode("small", null, "Ref: " + _toDisplayString(_ctx.apirc.message), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.apirc && _ctx.apirc.status !== 'OK' && !_ctx.numerrors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString(this.xlate('error-title')) + ":", 1),
          _createTextVNode(" " + _toDisplayString(_ctx.apirc.message), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.apirc || _ctx.apirc.status !== 'OK')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.numerrors)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.xlate("correct-errors")) + " (" + _toDisplayString(_ctx.numerrors) + "). ", 1))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            id: "contactForm",
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send_register && _ctx.send_register(...args)), ["prevent"])),
            class: "needs-validation text-start",
            novalidate: ""
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.xlate('label-firstname')) + ":", 1),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["form-control", 
                _ctx.submitted ? (_ctx.errors.firstname ? 'is-invalid' : 'is-valid') : ''
              ]),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstname) = $event)),
                  type: "text",
                  placeholder: _ctx.xlate('label-firstname')
                }, null, 10, _hoisted_10), [
                  [_vModelText, _ctx.firstname]
                ]),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.errors.firstname), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.xlate('label-lastname')) + ":", 1),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["form-control", 
                _ctx.submitted ? (_ctx.errors.lastname ? 'is-invalid' : 'is-valid') : ''
              ]),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastname) = $event)),
                  type: "text",
                  placeholder: _ctx.xlate('label-lastname')
                }, null, 10, _hoisted_14), [
                  [_vModelText, _ctx.lastname]
                ]),
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.errors.lastname), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.xlate('label-email')) + ":", 1),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["form-control", 
                _ctx.submitted ? (_ctx.errors.email ? 'is-invalid' : 'is-valid') : ''
              ]),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                  type: "email",
                  placeholder: _ctx.xlate('label-email')
                }, null, 10, _hoisted_19), [
                  [_vModelText, _ctx.email]
                ]),
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.errors.email), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.xlate('label-username')) + ":", 1),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(["form-control", 
                _ctx.submitted ? (_ctx.errors.username ? 'is-invalid' : 'is-valid') : ''
              ]),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.username) = $event)),
                  placeholder: _ctx.xlate('label-username')
                }, null, 10, _hoisted_24), [
                  [_vModelText, _ctx.username]
                ]),
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.errors.username), 1)
              ]),
              _createElementVNode("div", _hoisted_26, " * " + _toDisplayString(_ctx.xlate('username-desc')), 1)
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.subscribe) = $event)),
                    id: "subscribe"
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.subscribe]
                  ]),
                  _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.xlate('newsletter-join')), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["form-check-input", 
                  _ctx.submitted ? (_ctx.errors.agree ? 'is-invalid' : 'is-valid') : ''
                ]),
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.agree) = $event)),
                    id: "agree"
                  }, null, 2), [
                    [_vModelCheckbox, _ctx.agree]
                  ]),
                  _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.xlate('terms-accept')), 1),
                  _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.errors.agree), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("button", _hoisted_38, _toDisplayString(_ctx.xlate('register-action')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("a", _hoisted_41, _toDisplayString(_ctx.xlate("lnk-sendpass")), 1)
              ])
            ])
          ], 32)
        ]))
      : _createCommentVNode("", true)
  ]))
}