import * as util from '../modules/util';

export interface macrocalc {
    kcal: number,
    weight: number,
    max_protein_g: number,
    min_carbs_g: number,
    min_fat_g: number,
    does_weights: boolean
};

export interface dietparams {
    c: number;
    p: number;
    f: number;
    name: string;
    note?: string;
};

class mcDiet {
    calc_p(mc: macrocalc) { return this.sane_calc_p(mc) };
    calc_c(mc: macrocalc) { return this.sane_calc_c(mc) };
    calc_f(mc: macrocalc) { return this.sane_calc_f(mc) };
    name: string = "mcDiet Base Class";

    int_calc_macros(mc: macrocalc) : dietparams|undefined {
        return undefined;
    }
    calc_macros(mc: macrocalc): dietparams {
        var imc = this.int_calc_macros(mc);
        if (!imc) {
            imc = {
                p: this.calc_p(mc), f: this.calc_f(mc), c: this.calc_c(mc), name: this.name, note: ""
            }
        }
        imc.p =  util.myRound(imc.p, 0);
        imc.c =  util.myRound(imc.c, 0);
        imc.f =  util.myRound(imc.f, 0);
        var calced_kcal = util.kcal_from_pcf(imc.p, imc.c, imc.f);
        if (imc.note === "") {
            if ((calced_kcal+20) < mc.kcal) {
                imc.note = "Too many calories for proper macro calculation, forcing " + calced_kcal + " kcal.";
            }
        }
        return imc;
    }
    min_carbs(mc: macrocalc): number {
        return mc.min_carbs_g < 0 ? 30 : mc.min_carbs_g;
    }
    min_fat(mc: macrocalc): number {
        return mc.min_fat_g < 0 ? 30 : mc.min_fat_g;
    }
    min_protein(mc: macrocalc) {
        if (mc.does_weights) {
            return mc.weight * 0.8;
        } else {
            return mc.weight * 1.5;
        }
    }
    max_protein(mc: macrocalc) {
        return mc.max_protein_g < 0 ? mc.weight * 2.5 : mc.max_protein_g;
    }
    adjust_protein(protein: number, mc: macrocalc): number {
        if (protein < this.min_protein(mc)) {
            return this.min_protein(mc);
        } else if (protein > this.max_protein(mc)) {
            return this.max_protein(mc);
        } else {
            return protein;
        }
    }
    min_calories(mc: macrocalc): number {
        var m = util.kcal_from_pcf(this.min_protein(mc), this.min_carbs(mc), this.min_fat(mc));
        console.log("XXXX:" + m + "\n" + JSON.stringify(mc));
        return m;
    }
    sane_calories(kcals: number, mc: macrocalc): number {
        return Math.max(kcals, this.min_calories(mc));
    }
    sane_calc_p(mc: macrocalc) {
        return 123456789;
    }
    sane_calc_c(mc: macrocalc) {
        return 123456789;
    }
    sane_calc_f(mc: macrocalc) {
        return 123456789;
    }
};

export class mcZone extends mcDiet {
    readonly name: string = "Zone Diet (40-30-30)";
    int_calc_macros(mc: macrocalc): dietparams {
        var note = "";
        var p = this.adjust_protein(mc.kcal * 0.40 / 4, mc);
        var rest = (mc.kcal - (p*4));
        var c = Math.max(0, rest * 0.5 / 4);
        var f = Math.max(0, rest * 0.5 / 9);
        return {
            p: p, c: c, f: f, name: this.name, note: ""
        }        
    };
}

export class mcPSMF extends mcDiet {
    readonly name: string = "PSMF (Protein Sparing Modified Fast)";
    int_calc_macros(mc: macrocalc): dietparams {
        var p = (mc.kcal - util.kcal_from_pcf(0, this.min_carbs(mc), this.min_fat(mc))) / 4;
        p = Math.max(0,Math.min(this.min_protein(mc), p));
        var c = this.min_carbs(mc);
        var f = this.min_fat(mc);
        return {
            name: this.name,
            c: c,
            p: p,
            f: f,
        };
    }
    min_carbs(mc: macrocalc): number {
        return mc.min_carbs_g < 0 ? 20 : mc.min_carbs_g;
    }
    min_fat(mc: macrocalc): number {
        return mc.min_fat_g < 0 ? 20 : mc.min_fat_g;
    }
    min_protein(mc: macrocalc): number {
        return mc.weight * 1.5 * util.LBSPERKG; 
    }
}

export class mcKeto extends mcDiet {
    readonly name: string = "Keto (High Protein)";
    sane_calc_p(mc: macrocalc) {
        return this.adjust_protein(mc.weight * 2.204623, mc);
    };
    sane_calc_c(mc: macrocalc) {
        return this.min_carbs(mc);
    };
    sane_calc_f(mc: macrocalc) {
        var fc = mc.kcal - (this.sane_calc_p(mc) * 4) - (this.sane_calc_c(mc) * 4);
        return fc / 9;
    };
}

export class mcKetoStd extends mcDiet {
    readonly name: string = "Keto (Standard)";
    sane_calc_p(mc: macrocalc) {
        return this.adjust_protein(mc.kcal * 0.15 / 4, mc);
    };
    sane_calc_c(mc: macrocalc) {
        return mc.kcal * 0.05 / 4;
    };
    sane_calc_f(mc: macrocalc) {
        var fc = mc.kcal - (this.sane_calc_p(mc) * 4) - (this.sane_calc_c(mc) * 4);
        return fc / 9;
    };
}

export class mcBB extends mcDiet {
    name: string = "Fitness (High Carb)";
    sane_calc_p(mc: macrocalc) {
        return this.adjust_protein(mc.weight * 2.204623, mc);
    };
    sane_calc_c(mc: macrocalc) {
        var fc = mc.kcal - (this.sane_calc_p(mc) * 4) - (this.sane_calc_f(mc) * 9);
        return fc / 4;
    };
    sane_calc_f(mc: macrocalc) {
        return 60;
    };
}

export function getDietList(): mcDiet[] {
    return [
        new mcKeto(),
        new mcBB(),
        new mcZone(),
        new mcKetoStd(),
        new mcPSMF(),
    ];
}
