
import { defineComponent } from "vue";
import * as api from "@/modules/api";
import i18nstr from "@/modules/i18n";

export default defineComponent({
  name: "Contact",
  data() {
    return {
      name: "",
      name_class: "",
      email: "",
      email_class: "",
      message: "",
      message_class: "",
      url: "",
      catid: '' as string,
      apirc: undefined as api.api_res | undefined,
      errors: {} as api.errorfields,
      submitted: false,
      catlist: [] as api.errorfields[],
      cats: {} as api.errorfields
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.url = urlParams.get("url") as string;
    const rc = (await api.do_api_call(
        "messageconf",
        {
        },
        false
    )) as api.api_res;
    if (rc.status === 'OK' && rc.data.subjects) {
      this.cats = rc.data.subjects;
      for(var index in rc.data.subjects) {
        if (!this.catid) {
          this.catid = index;
        } 
        this.catlist.push({
          "k": index,
          "v": rc.data.subjects[index],
        });
      }
      console.log(JSON.stringify(this.catlist, undefined, "  "));
    }
  },
  computed: {
    numerrors(): number {
      return api.num_keys(this.errors);
    },
  },
  methods: {
    xlate(str: string): string {
      return i18nstr(str);
    },
    validate_form() {
      this.errors = {};
      if (!api.validate_email(this.email)) {
        this.errors.email = this.xlate('invalid-email');
      }
      if (!this.name) {
        this.errors.name = this.xlate('missing-name');
      }
      if (!this.message) {
        this.errors.message = this.xlate('missing-message');
      }
      return api.is_empty(this.errors);
    },
    async send_message() {
      this.submitted = true;
      if (!this.validate_form()) {
        return;
      }
      this.apirc = (await api.do_api_call(
        "message",
        {
          name: this.name,
          email: this.email,
          message: this.message,
          url: this.url,
          catid: this.catid || "" as string,
          category: this.catid ? this.cats[this.catid] : "[no category]"
        },
        false
      )) as api.api_res;
      for (let index in this.apirc.errors) {
        this.errors[index] = this.apirc.errors[index];
      }
    },
  },
});
