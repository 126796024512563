
import { defineComponent } from "vue";
import * as api from "@/modules/api";
import i18nstr from "@/modules/i18n";

export default defineComponent({
  name: "Login",
  data() {
    return {
      email: "",
      currpass: "",
      loggedin: false,
      apirc: undefined as api.api_res | undefined,
      errors: {} as api.errorfields,
      submitted: false,
      curUser: undefined as api.user|undefined,
    };
  },
  async created() {
    if (api.cur_user()) {
      this.loggedin = true;
    }
  },
  computed: {
    numerrors(): number {
      return api.num_keys(this.errors);
    },
  },
  methods: {
    xlate(str: string): string {
      return i18nstr(str);
    },
    validate_form(): boolean {
      this.errors = {};
      if (!api.validate_email(this.email)) {
        this.errors.email = this.xlate("invalid-email");
      }
      if (this.currpass === "") {
        this.errors.currpass = this.xlate("enter-pw");
      }
      return api.is_empty(this.errors);
    },
    async send_login() {
      this.submitted = true;
      if (!this.validate_form()) {
        return;
      }
      var rc = (await api.do_api_call(
        "login",
        {
          email: this.email,
          currpass: this.currpass,
        },
        false
      )) as api.api_res;

      if (rc.status === "OK") {
        const urlParams = new URLSearchParams(window.location.search);
        const redir = urlParams.get("redir");
        if (redir) {
          await api.redirect(redir);
        }
      }
      for (let index in rc.errors) {
        this.errors[index] = rc.errors[index];
      }
      this.apirc = rc;
    },
  },
});
