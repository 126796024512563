
import { defineComponent, inject } from "vue";
import { xxstate } from "../store/index";
import * as util from "@/modules/util";

export default defineComponent({
  name: "goals",
  data() {
    return {
      state: inject("state") as xxstate,
    };
  },
  computed: {
    target_weight(): string {
      return this.state.display_weight(this.state.target_weight(), true, 1);
    },
    weight_lost(): string {
      return this.state.display_weight(
        Math.abs(this.state.weight_lost()),
        true,
        1
      );
    },
    target_lbm(): string {
      return this.state.display_weight(
        this.state.g.target_lbm(this.state.s),
        true,
        1
      );
    },
    weekly_allowance(): number {
      return util.myRound(this.state.get_daily_allowance() * 7, 0);
    },
    weekly_deficit(): number {
      return util.myRound(this.state.total_deficit() * 7, 0);
    },
    loss_per_week(): string {
      return this.state.display_weight(Math.abs(
        this.weekly_deficit / util.KCALKG),
        true,
        2
      );
    },
    loss_per_week_pct(): number {
      var pct =
        ((this.state.total_deficit() * 7) / util.KCALKG / this.state.s.weight) *
        100;
      return util.myRound(Math.abs(pct), 2);
    },
    ideal_weight(): string {
      return this.state.display_weight(this.state.s.ideal_weight(), true, 1);
    },
  },
  methods: {},
});
