
import { defineComponent, inject } from "vue";
import { xxstate } from "../store/index";
import * as macrostuff from "../models/diets";

export default defineComponent({
  name: "macros",
  data() {
    return {
      state: inject("state") as xxstate,
    };
  },
  computed: {
    diet_list(): macrostuff.dietparams[] {
      var ml = macrostuff.getDietList();
      var list = [] as macrostuff.dietparams[];
      var tw: number = this.state.g.target_weight(this.state.s);
      var kd: number = this.state.get_daily_allowance();
      ml.forEach((ml) => {
        list.push(
          ml.calc_macros({
            kcal: this.state.get_daily_allowance(),
            weight: tw,
            max_protein_g: this.state.x.max_protein_g,
            min_carbs_g: this.state.x.min_carbs_g,
            min_fat_g: this.state.x.min_fat_g,
            does_weights: this.state.x.does_weights,
          })
        );
      });
      return list;
    },
  },
  methods: {},
});
