import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bg-light px-5 py-3 rounded" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success",
  role: "alert"
}
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = {
  key: 1,
  class: ""
}
const _hoisted_5 = {
  key: 0,
  class: "alert alert-danger mb3",
  role: "alert"
}
const _hoisted_6 = {
  key: 0,
  class: "alert alert-danger mb3",
  role: "alert"
}
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = {
  class: "form-label",
  for: "name"
}
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { class: "invalid-feedback" }
const _hoisted_11 = { class: "mb-3" }
const _hoisted_12 = {
  class: "form-label",
  for: "email"
}
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "invalid-feedback" }
const _hoisted_15 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_16 = {
  class: "form-label",
  for: "message"
}
const _hoisted_17 = ["value"]
const _hoisted_18 = { class: "mb-3" }
const _hoisted_19 = {
  class: "form-label",
  for: "message"
}
const _hoisted_20 = ["placeholder"]
const _hoisted_21 = { class: "invalid-feedback" }
const _hoisted_22 = {
  key: 2,
  class: "mb-3"
}
const _hoisted_23 = {
  class: "form-label",
  for: "url"
}
const _hoisted_24 = { class: "d-grid" }
const _hoisted_25 = {
  class: "btn btn-primary btn-lg",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.xlate('contact-title')), 1),
    (_ctx.apirc && _ctx.apirc.status === 'OK')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.xlate('contact-sent')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.xlate('contact-msg')) + ". ", 1),
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("em", null, [
              _createElementVNode("small", null, "TicketId: " + _toDisplayString(_ctx.apirc.message), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.apirc || _ctx.apirc.status !== 'OK')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.apirc && _ctx.apirc.status !== 'OK')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.xlate('error-title')), 1),
                _createElementVNode("strong", null, _toDisplayString(_ctx.apirc.message), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            id: "contactForm",
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send_message && _ctx.send_message(...args)), ["prevent"])),
            class: "needs-validation row g-3 text-start",
            novalidate: ""
          }, [
            (_ctx.numerrors)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.xlate('correct-errors')) + " (" + _toDisplayString(_ctx.numerrors) + "). ", 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.xlate('label-name')) + ":", 1),
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(["form-control", _ctx.submitted ? _ctx.errors.name ? 'is-invalid' : 'is-valid' : '']),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                type: "text",
                placeholder: _ctx.xlate('label-name')
              }, null, 10, _hoisted_9), [
                [_vModelText, _ctx.name]
              ]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.errors.name), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.xlate('label-email')) + ":", 1),
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(["form-control", _ctx.submitted ? _ctx.errors.email ? 'is-invalid' : 'is-valid' : '']),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                placeholder: _ctx.xlate('label-email')
              }, null, 10, _hoisted_13), [
                [_vModelText, _ctx.email]
              ]),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.errors.email), 1)
            ]),
            (Object.keys(this.cats).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.xlate('label-category')) + ":", 1),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-md",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.catid) = $event))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.catlist, (item) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: item.k,
                        key: item.k
                      }, _toDisplayString(item.v), 9, _hoisted_17))
                    }), 128))
                  ], 512), [
                    [
                      _vModelSelect,
                      _ctx.catid,
                      void 0,
                      { number: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.xlate('label-message')) + ":", 1),
              _withDirectives(_createElementVNode("textarea", {
                class: _normalizeClass(["form-control", _ctx.submitted ? _ctx.errors.message ? 'is-invalid' : 'is-valid' : '']),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.message) = $event)),
                placeholder: _ctx.xlate('label-message'),
                style: {"height":"10rem"}
              }, null, 10, _hoisted_20), [
                [_vModelText, _ctx.message]
              ]),
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.errors.message), 1)
            ]),
            (_ctx.url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.xlate('label-url')) + ":", 1),
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.url) = $event)),
                    type: "text",
                    readonly: "1"
                  }, null, 512), [
                    [_vModelText, _ctx.url]
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("button", _hoisted_25, _toDisplayString(_ctx.xlate('button-sendmsg')), 1)
            ])
          ], 32)
        ]))
      : _createCommentVNode("", true)
  ]))
}