
import { defineComponent, inject, isReactive } from "vue";
import { xxstate } from "../store/index";
import { oneActivity } from "../models/activity";

const newactivity = {
  id: undefined,
  name: "New Activity",
  minutes: 0,
  period: "d",
  mets: 1,
} as oneActivity;

export default defineComponent({
  name: "activities",
  data() {
    return {
      state: inject("state") as xxstate,
      oo: Object.assign({}, newactivity) as oneActivity,
    };
  },
  computed: {
    can_save(): boolean {
      if (
        this.oo.name !== "" &&
        this.oo.minutes &&
        this.oo.minutes > 0 &&
        this.oo.mets &&
        this.oo.mets > 0
      ) {
        return true;
      }
      return false;
    },
    activity_list(): oneActivity[] {
      // console.log(JSON.stringify(this.state.a.get_activities(this.state.s)));
      return this.state.a.get_activities(this.state.s);
    },
    period_list(): any[] {
      return [
        { value: "d", name: "Day" },
        { value: "w", name: "Week" },
      ];
    },
  },
  mounted(): void {
    this.reset_inputs();
  },
  methods: {
    reset_inputs(): void {
      this.oo = Object.assign({}, newactivity) as oneActivity;
    },
    edit_one(id: number): void {
      this.oo = Object.assign({}, this.state.a.activities[id]);
    },
    add_update() {
      var copy = Object.assign({}, this.oo) as oneActivity;
      if (this.oo.id != undefined) {
        this.state.a.activities.splice(this.oo.id, 1, copy);
      } else {
        this.state.a.activities.push(copy);
      }
      this.state.a.recalc_activities(this.state.s);
      this.state.force_update();
      this.reset_inputs();
    },
    delete_one(id: number): void {
      if (confirm("Delete this activity?")) {
        if (id === this.oo.id) {  // Delete the one being edited? If so, reset.
          this.reset_inputs();
        }
        this.state.a.delete_activity(id, this.state.s);
        this.state.force_update();
      }
    },
  },
});
