
import { defineComponent } from "vue";
import * as api from "@/modules/api";
import i18nstr from "@/modules/i18n";

export default defineComponent({
  name: "Register",
  data() {
    return {
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      subscribe: true,
      agree: false,
      apirc: undefined as api.api_res | undefined,
      errors: {} as api.errorfields,
      submitted: false,
    };
  },
  computed: {
    numerrors(): number {
      return api.num_keys(this.errors);
    },
  },
  methods: {
    xlate(str: string): string {
      return i18nstr(str);
    },
    validate_form() {
      this.errors = {};
      if (!api.validate_email(this.email)) {
        this.errors.email = this.xlate('invalid-email');
      }
      if (this.username === "") {
        this.errors.username = this.xlate('missing-username');
      }
      if (!api.validate_username(this.username)) {
        this.errors.username = this.xlate("invalid-username");
      }
      if (this.firstname === "") {
        this.errors.firstname = this.xlate('missing-firstname');
      }
      if (!api.validate_name(this.firstname)) {
        this.errors.firstname = this.xlate("invalid-name");
      }
      if (this.lastname === "") {
        this.errors.lastname = this.xlate('missing-lastname');
      }
      if (!api.validate_name(this.lastname)) {
        this.errors.lastname = this.xlate("invalid-name");
      }
      if (!this.agree) {
        this.errors.agree = this.xlate('missing-terms');
      }
      return api.is_empty(this.errors) ? true : false;
    },
    async send_register() {
      this.submitted = true;
      if (!this.validate_form()) {
        return;
      }
      let rc = (this.apirc = (await api.do_api_call(
        "register",
        {
          firstname: this.firstname,
          lastname: this.lastname,
          username: this.username,
          email: this.email,
          subscribe: this.subscribe ? 1 : 0,
        },
        false
      )) as api.api_res);
      for (let index in rc.errors) {
        this.errors[index] = rc.errors[index];
      }
    },
  },
});
