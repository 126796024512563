
import { defineComponent } from "vue";
import * as api from "@/modules/api";
import i18nstr from "@/modules/i18n";

export default defineComponent({
  name: "Logout",
  data() {
    return {
      apirc: undefined as api.api_res|undefined,
      jobid: "",
      render: false,
      errors: {} as api.errorfields,
      submitted: false,
    };
  },
  computed: {
    numerrors(): number {
      return api.num_keys(this.errors);
    },
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.jobid = urlParams.get("jobid") as string;
    if (this.jobid) {
      const rc = await this.send_api_confirm();
      if (rc.status !== 'OK') {
        this.render = true;
      }
      this.submitted = true;
    } else {
      this.render = true;
    }
  },
  methods: {
    xlate(str: string): string {
      return i18nstr(str);
    },
    validate_form() {
      this.errors = {};
      if (!this.jobid || (this.jobid === '')) {
          this.errors.jobid = this.xlate('missing-code');
      } else if (!api.validate_nanoid(this.jobid)) {
          this.errors.jobid = this.xlate('invalid-code');
      }
      return api.is_empty(this.errors);
    },
    async send_api_confirm() {
      this.apirc = (await api.do_api_call(
        "confirm",
        {
          jobid: this.jobid,
        },
        false
      )) as api.api_res;
      console.log(JSON.stringify(this.apirc, undefined, "  "));
      for (let index in this.apirc.errors) {
        this.errors[index] = this.apirc.errors[index];
      }
      return this.apirc;
    },
    async send_confirm() {
      this.submitted = true;
      this.apirc = undefined;
      if (!this.validate_form()) {
        return;
      }
      await this.send_api_confirm();
    },
  },
});
