import { reactive } from "vue";
import xxstats from '../models/stats';
import xxgoal from '../models/goal';
import xxactivity from '../models/activity';
import * as util from '../modules/util';

type tprotein = "cweight" | "clbm" | "tweight" | "tlbm";
interface tproteinname {
  value: tprotein;
  name: string;
};

const tprotein_names = [
  { value: "cweight", name: "Current Body Weight" },
  { value: "clbm", name: "Current LBM" },
  { value: "tweight", name: "Target Body Weight" },
  { value: "tlbm", name: "Target LBM" },
] as tproteinname[];

export interface xxsettings {
  metric: boolean,
  predict_bf: boolean,
  lbm_loss: number,
  bmr_formula: "hbe" | "msje" | "rbhe",
  bmr_formula_bf: "kmf",
  max_protein_g: number,
  target_protein: tprotein,
  min_carbs_g: number,
  min_fat_g: number,
  does_weights: boolean,
};

export class xxstate {
  forceUpdate: number = 0;
  s: xxstats = new xxstats;
  a: xxactivity = new xxactivity;
  g: xxgoal = new xxgoal;
  x: xxsettings = {
    metric: true,
    predict_bf: true,
    lbm_loss: 25,
    bmr_formula: "msje",
    bmr_formula_bf: "kmf",
    max_protein_g: 250,
    target_protein: "cweight",
    min_carbs_g: 27,
    min_fat_g: 26,
    does_weights: true
  };
  protein_names(): tproteinname[] {
    return tprotein_names;
  };
  total_deficit(): number {
    return this.g.calc_daily_deficit(this.s) +
      this.a.daily_kcal(this.s);
  };
  get_total_tdee(): number {
    return this.s.get_base_tdee() + this.a.daily_kcal(this.s);
  };
  get_daily_allowance(): number {
    return Math.max(0, this.s.get_base_tdee() 
     - this.g.calc_daily_deficit(this.s)
     + this.a.daily_kcal(this.s));
    //);
  };
  goal_days(): number {
    if (this.g.ttype === "weight" || this.g.ttype === "bodyfat") {
      return this.g.days;
    } else {
      var d = this.total_deficit();
      var w = this.g.target_weight(this.s);
      var c = this.s.weight - w;
      return util.myRound((c * util.KCALKG) / d, 0);
    }
  };
  goal_date(): string {
    return util.date_from_days(this.goal_days());
  };
  target_weight(): number {
    return this.g.target_weight(this.s);
  };
  weight_lost(): number {
    return this.s.weight - this.g.target_weight(this.s);
  };
  has_accurate_bodyfat(): boolean {
    return (this.s.use_bodyfat && this.s.has_bodyfat() && (this.g.ttype === 'bodyfat')) ? true : false;
  }
  kg_to_lbs(kg: number, fix?: number) {
    return util.kg_to_lbs(kg, fix);
  }
  lbs_to_kg(kg: number, fix?: number) {
    return util.lbs_to_kg(kg, fix);
  }
  weight_unit(): string {
    return this.x.metric ? "kg" : "lbs";
  }
  display_weight(num: number, addunit: boolean, fix?: number): string {
    var val : number = this.x.metric ? num : num * util.LBSPERKG;
    var fixed = fix ? val.toFixed(fix) : val;
    if (addunit) {
      return fixed + " " + this.weight_unit();
    }
    return fixed + "";
  }
  min_target_weight(): number {
    return Math.ceil(this.x.metric ? this.s.min_weight() : this.kg_to_lbs(this.s.min_weight()));
  }
  max_target_weight(): number {
    return Math.floor(this.x.metric ? this.s.max_weight() : this.kg_to_lbs(this.s.max_weight()));
  }
  min_weight(): number {
    return Math.ceil(this.x.metric ? 45 : this.kg_to_lbs(45));
  }
  max_weight(): number {
    return Math.floor(this.x.metric ? 250 : this.kg_to_lbs(250));
  }
  sane_target_weight(weight: number, convert: boolean): number {
    if (convert && !this.x.metric) {
      weight = this.kg_to_lbs(weight, 0);
    } 
    return Math.min(Math.max(weight, this.min_target_weight()), this.max_target_weight());
  }
  sane_weight(weight: number, convert: boolean): number {
    if (convert && !this.x.metric) {
      weight = this.kg_to_lbs(weight, 0);
    } 
    return Math.min(Math.max(weight, this.min_weight()), this.max_weight());
  }
  weight_to_kg(weight: number): number {
    var w = util.myRound(this.x.metric ? weight : weight / util.LBSPERKG, 1);
    console.log("X " + this.x.metric + " Y " + weight + "Z " + w);
    return w;
  }
  recalc_activities() {
    this.a.recalc_activities(this.s);
  }
  force_update(): void {
    this.forceUpdate++;
  }
};

export const state = reactive(new xxstate);

export default state;
