<template>
  <keep-alive>
    <bmr />
  </keep-alive>
</template>

<script>
import bmr from "../components/BMR.vue";

export default {
  name: "Calc",
  components: {
    bmr,
  },
};
</script>
