
import { inject } from "vue";
import bmr from "@/components/BMR.vue";
import { xxstate } from "@/store/index";
import * as tdeeapi from "@/modules/tdee-api";
import navbar from "@/components/NavBar.vue";

export default {
  name: "App",
  components: {
    bmr, navbar
  },
  created() {
    tdeeapi.load_data();
  },
  data() {
    return {
      state: inject("state") as xxstate,
    };
  },
};
