import * as util from '../modules/util';

let alevels = [
    { factor: 1.2, name: "Sedentary" },
    { factor: 1.375, name: "Lightly active" },
    { factor: 1.55, name: "Moderately active" },
    { factor: 1.725, name: "Very active" },
    { factor: 1.9, name: "Hyper active" },
];

let fnames2 = [
    { value: "msje", text: "Mifflin-St Jeor Equation", },
    { value: "hbe", text: "Harris-Benedict Equation" },
    { value: "rhbe", text: "Revised Harris-Benedict Equation" }
];
let fnames3 = [
    { value: "kmf", text: "Katch-McArdle Formula" },
];

export default class xxstats {
    formula: "hbe" | "msje" | "rbhe" = "hbe";
    formula_bf: "kmf" = "kmf";
    use_bodyfat: boolean = false;
    age: number = 36;
    gender: "m" | "f" = "f";
    height: number = 172;
    weight: number = 77;
    bodyfat: number = 30;
    alevel: number = 1.375;
    formula_options2(): any[] {
        return fnames2;
    }
    formula_options3(): any[] {
        return fnames3;
    }
    activity_options(): any[] {
        return alevels;
    }
    get_base_tdee(): number {
        return this.get_bmr() * this.alevel;
    }
    get_neat(): number {
        return this.get_base_tdee() - this.get_bmr();
    }
    get_bmi(weight?: number): number {
        var w = weight ? weight : this.weight;
        return (w / this.height / this.height) * 10000;
    }
    min_weight(): number { // Minumim weight based on BMI=18.5
        return Math.pow(this.height,2) * 18.5 / 10000;
    }
    max_weight(): number { // Maximum weight based on BMI=40
        return Math.pow(this.height,2) * 40 / 10000;
    }
    has_bodyfat() : number {
        if (this.use_bodyfat && this.bodyfat) {
            return this.bodyfat;
        }
        return 0;
    }
    get_bodyfat(): number {
        var bf: number = this.has_bodyfat();
        var ebf: number = util.myRound(this.estimate_bodyfat(), 2);
        return bf ? bf : ebf;
    }
    get_ffm(): number {
        return this.weight - (this.weight / 100) * this.bodyfat;
    }
    get_estimated_ffm(): number {
        return this.weight - (this.weight / 100) * this.estimate_bodyfat();
    }
    ideal_weight(): number {
        var hi = (this.height - 5 * 30.48) / 2.54;
        if (this.gender === "m") {
            return 50 + 2.3 * hi; // Devine: 50 kg + 2.3 kg per inch over 5 feet.
        } else {
            return 49 + 1.7 * hi; // Robinson: Ideal Body Weight (kg) = 49 kg + 1.7 kg per inch over 5 feet.
        }
    }
    estimate_bodyfat(): number {
        return util.estimate_bodyfat(this.age, this.get_bmi(), this.gender);
    }
    get_bmr(): number {
        var xbmr: number = 0;

        if (this.use_bodyfat) {
            console.log("Using KMF and bodyfat");
            return 370 + 21.6 * (1 - this.get_bodyfat() / 100) * this.weight;
        }

        if (this.formula === 'msje') {
            var gmod = this.gender === "m" ? 5 : -161;
            xbmr = Math.round(
                10 * this.weight + 6.25 * this.height - 5 * this.age + gmod
            );
        } else if (this.formula === "hbe") {
            if (this.gender === "m") {
                // Men: BMR = 13.397W + 4.799H - 5.677A + 88.362
                xbmr = 13.397 * this.weight + 4.799 * this.height - 5.677 * this.age + 88.362
            } else {
                // Women: BMR = 9.247W + 3.098H - 4.330A + 447.593
                xbmr = 9.247 * this.weight + 3.098 * this.height - 4.33 * this.age + 447.593;
            }
        } else if (this.formula === "rbhe") {
            if (this.gender === 'm') {
                // Revised 88.362 + (13.397 x weight in kg) + (4.799 x height in cm) – (5.677 x age in years)
                xbmr = 88.362 + (13.397 * this.weight) + (4.799 * this.height) - (5.677 * this.age);
            } else {
                // Women: BMR = 447.593 + (9.247 x weight in kg) + (3.098 x height in cm) – (4.330 x age in years)
                xbmr = 447.593 + (9.247 * this.weight) + (3.098 * this.height) - (4.330 * this.age);
            }
        }
        return Math.round(xbmr);
    };
}
