
type trsx = {
    [lang: string] : { [id: string] : string };
};

const trans = {
    en: {
        "login-title": "Log in",
        "login-ok": "You have successfully logged in",
        "enter-pw": "Please enter your password",
        "label-pw": "Password",
        "label-currpw": "Current password",
        "label-newpw": "New password",
        "label-repeatpw": "Repeat new password",
        "enter-currpw": "Enter current password",
        "error-title": "Something not so wonderful just happened",
        "correct-errors": "You must correct the below errors",
        "invalid-email": "Not a valid email address",
        "label-email": "Email address",
        "lnk-sendpass": "Forgot password?",
        "lnk-register": "Registrer new user",
        "chpass-title": "Change Password",
        "chpass-ok": "Password changed",
        "passwd-desc": "The new password must consist of at least 8 characters and contain at least one lower and upper case letter and a number.",
        "invalid-pw": "Invalid password",
        "mismatch-pw": "Both passwords must be the same",
        "chemail-title": "Change email address",
        "chemail-ok": "Your email address is updated",
        "label-curremail": "Current email address",
        "label-newemail": "New email address",
        "label-repeatemail": "Repeat new email address",
        "error-emailmismatch": "Email addresses must be the same",
        // Contact
        "contact-title": "Contact us",
        "contact-sent": "Message sent",
        "contact-msg": "We will answer your message as soon as possible",
        "label-name": "Name",
        "label-category": "Category",
        "label-message": "Message",
        "label-url": "Link/Page/URL",
        "button-sendmsg": "Send Message",
        "missing-name": "Enter your name",
        "missing-message": "Enter a message",
        // Register
        "register-title": "User Registration",
        "register-done": "User registration received",
        "register-confirm": "We just sent you an email. You must confirm your signup as described in the email to complete the registration",
        "label-firstname": "First name",
        "label-lastname": "Last name",
        "label-username": "Username",
        "username-desc": "This is your public name on this service and will be visible to others. It may contain letters, numbers, space, _, - and !",
        "newsletter-join": "Join our newsletter",
        "terms-accept": "Accept the our terms and conditions",
        "register-action": "Register User",
        "missing-firstname": "Enter your first name",
        "missing-lastname": "Enter your last name",
        "missing-username": "Enter a username",
        "missing-terms": "You must accept the terms",
        // Sendpass
        "title-sendpass": "Reset password",
        "action-sendpass": "Send new password",
        "rcpt-sendpass1": "New password sent",
        "rcpt-sendpass2": "We just sent a new password to the specified email address",
        // DelUser
        "title-deluser": "Delete user",
        "action-deluser": "Delete the user",
        "missing-delete": "Please confirm deletion",
        "rcpt-deluser1": "User deleted",
        "rcpt-deluser2": "The user and associated data will be deleted in 30 days, unless you log inn before",
        "rcpt-deluser3": "Warning: The user will be deleted in 30 days. After that, the user and associated data will be permanently deleted",
        "label-deleteconfirm": "Confirm user deletion",
        // Confirm
        "title-confirm": "Confirm action",
        "action-confirm": "Confirm",
        "label-code": "Confirmation code",
        "rcpt-confirm": "Confirmation received",
        "missing-code": "You must enter a confirmation code",
        "invalid-code": "Invalid confirmaion code",
        // Logout
        "title-logout": "Logout",
        "err-nologout": "You're not logged in",
        "rcpt-logout": "You have been logged out",
        // ChName
        "title-chname": "Update name",
        "action-chname": "Update",
        "rcpt-chname": "User updated",
        "invalid-username": "Not a valid username",
        "invalid-name": "Not a valid name",
    },
    no: {
        "login-title": "Logg inn",
        "login-ok": "Du er nå innlogget",
        "enter-pw": "Du må skrive inn passordet ditt",
        "label-pw": "Passord",
        "label-currpw": "Nåværende passord",
        "label-newpw": "Nytt passord",
        "label-repeatpw": "Gjenta nytt passord",
        "enter-currpw": "Du må skrive inn nåværende passord",
        "error-title": "Noe forferdelig har skjedd",
        "correct-errors": "Du må korrigere feilene under",
        "invalid-email": "Ugyldig e-postadresse",
        "label-email": "E-postadresse",
        "lnk-sendpass": "Glemt passordet",
        "lnk-register": "Registrer ny bruker",
        "chpass-title": "Bytt Passord",
        "chpass-ok": "Passordet ditt er byttet",
        "passwd-desc": "Det nye passordet må inneholde minst 8 tegn, med minst en stor bokstav, en liten bokstav og et tall.",
        "invalid-pw": "Ugyldig passord",
        "mismatch-pw": "Begge passordene må være like",
        "chemail-title": "Bytt e-postsdresse",
        "chemail-ok": "E-postadressen din er oppdatert",
        "label-curremail": "Nåværende e-postadresse",
        "label-newemail": "Ny e-postadresse",
        "label-repeatemail": "Gjenta ny e-postadresse",
        "error-emailmismatch": "Adressene må være like",
        // Contact
        "contact-title": "Kontakt oss",
        "contact-sent": "Meldingen er sendt",
        "contact-msg": "Vi vil svare på meldingen så snart vi har anledning",
        "label-name": "Navn",
        "label-category": "Kategori",
        "label-message": "Melding",
        "label-url": "Lenke/Side/URL",
        "button-sendmsg": "Send melding",
        "missing-name": "Skriv inn navnet ditt",
        "missing-message": "Skriv inn en melding",
        // Register
        "register-title": "Brukerregistrering",
        "register-done": "Registreringen er mottat",
        "register-confirm": "Vi har sendt deg en e-post der du må bekrefte registreringen for å fullføre",
        "label-firstname": "Fornavn",
        "label-lastname": "Etternavn",
        "label-username": "Brukernavm",
        "username-desc": "Dette er ditt synlige brukernavn på tjenesten. Kan inneholde bokstaver, tall, mellomrom, _, - og !",
        "newsletter-join": "Meld meg på vårt nyhetsbrev",
        "terms-accept": "Godta personvernvilkårene",
        "register-action": "Registrer bruker",
        "missing-firstname": "Oppgi fornavnet ditt",
        "missing-lastname": "Oppgi etternavnet ditt",
        "missing-username": "Oppgi ønsket brukernavn",
        "missing-terms": "Du må godta vilkårene",
        // Sendpass
        "title-sendpass": "Send passord",
        "action-sendpass": "Send nytt passord",
        "rcpt-sendpass1": "Nytt passord er sendt",
        "rcpt-sendpass2": "Vi har sendt et nytt passord til e-postadressen du oppga",
        // DelUser
        "title-deluser": "Slett bruker",
        "action-deluser": "Slett brukeren",
        "missing-delete": "Du må bekrefte slettingen",
        "rcpt-deluser1": "Bruker slettet",
        "rcpt-deluser2": "Brukeren og alle data slettes om 30 dager, med mindre du logger inn før",
        "rcpt-deluser3": "Advarsel: Brukeren slettes om 30 dager. Etter dette er det ikke mulig å få tilbake brukeren eller tilhørende data",
        "label-deleteconfirm": "Bekreft slettingen",
        // Confirm
        "title-confirm": "Bekreft registrering",
        "action-confirm": "Bekreft",
        "label-code": "Bekreftelseskode",
        "rcpt-confirm": "Bekreftelse mottatt",
        "missing-code": "Du må skrive inn en bekreftelseskode",
        "invalid-code": "Ugyldig bekreftelsesid",
        // Logout
        "title-logout": "Utlogging",
        "err-nologout": "Du er ikke innlogget",
        "rcpt-logout": "Du er nå utlogget",
        // ChName
        "title-chname": "Oppdater navn",
        "action-chname": "Oppdater",
        "rcpt-chname": "Brukeren oppdatert",
        "invalid-username": "Ikke et gyldig brukernavn",
        "invalid-name": "Ikke et gyldig navn",
    }
} as trsx;

var the_lang: string;

function get_lang() : string {
    if (the_lang) {
        return the_lang;
    }
    const host = window.location.hostname;
    const re = /\.no$/i;
    the_lang = re.test(host) ? "no" : "en";
    return the_lang;
}

export function i18nstr(idstr: string) {
    const l = get_lang();
    if (trans[l][idstr]) {
        return trans[l][idstr];
    }
    return `No translation for "${idstr}"`;
}

export default i18nstr;
