import xxstats from './stats';
import * as util from '../modules/util';

let ttype_list = [
  { value: "deficit", text: "Daily Kcalorie Deficit", },
  { value: "weight", text: "Target Weight", },
  { value: "bodyfat", text: "Target Body Fat %", },
];

export default class xxgoal {
  bodyfat: number = 22;
  weight: number = 68;
  days: number = 90;
  deficit: number = 500;
  ttype: "deficit" | "weight" | "bodyfat" = "weight";
  ttype_options(): any[] {
    return ttype_list;
  }
  target_bmi(s: xxstats): number {
    return s.get_bmi(this.target_weight(s));
  }
  estimate_bodyfat(s: xxstats): number {
    return util.estimate_bodyfat(s.age, this.target_bmi(s), s.gender);
  }
  target_bodyfat(s: xxstats) {
    if ((this.ttype === 'bodyfat') && this.bodyfat) {
      return this.bodyfat;
    }
    if (s.use_bodyfat && s.bodyfat) {
      var iffm = s.get_ffm();
      return 100 - (iffm / this.weight * 100);
    } 
    return this.estimate_bodyfat(s);
  }
  target_lbm(s: xxstats): number {
    if ((this.ttype === 'bodyfat') && this.bodyfat) {
      return this.weight - (this.weight / 100) * this.bodyfat;
    } else {
      return this.weight - (this.weight / 100) * this.estimate_bodyfat(s);
    }
  }
  target_weight(s: xxstats): number {
    if ((this.ttype === 'bodyfat') && this.bodyfat) {
        return s.get_ffm() / ((100 - this.bodyfat) / 100);
    } else {
        return this.weight;
    }
  }
  calc_daily_deficit(s: xxstats): number {
    switch (this.ttype) {
      case 'deficit':
        return this.deficit;
      default:
        return Math.round(
          ((s.weight - this.target_weight(s)) * util.KCALKG) / this.days);
    }
  }
}
