import xxstats from '@/models/stats';
import * as util from '../modules/util';
import { toRaw } from 'vue';

export interface oneActivity {
    id: undefined|number,
    name: string,
    mets: number,
    minutes: number,
    period: "w" | "d",
    netkcal: number,
    kcal: number,
};

const defActivities: oneActivity[] = [
    { id: 0, name: "Walking", minutes: 30, period: "d", mets: 3.5, kcal: 0, netkcal: 0 },
    { id: 1, name: "Cardio", minutes: 120, period: "w", mets: 8, kcal: 0, netkcal: 0 },
    { id: 2, name: "Weight Training", minutes: 180, period: "w", mets: 5, kcal: 0, netkcal: 0 },
];

export class xxactivity {
    activities: oneActivity[] = defActivities; //  defActivities.slice();
    netkcal: number = 0;
    minutes: number = 0;
/*     met_cals(stats: xxstats, met: number, minutes: number): number {
        var rmr = stats.get_rmr();
        var rmrm = rmr / 1440;  // RMR per minute
        var metm = rmrm * met * minutes; // MET Minutes
        var rmrd = metm - (rmrm * minutes);  // Diff MET Minutes RMR Minutes
        return rmrd;
    };
 */    recalc_activities(stats: xxstats): void {
        var bmr: number = stats.get_bmr();
        var bmrm: number = bmr / (24 * 60);  // BMR per minute - METs is calculated per minute of activity
        // var ncal = toRaw(this.netkcal);
        var nrthis = toRaw(this); // Get non-reactive pointer to original object to prevent recursion
        nrthis.netkcal = 0;
        nrthis.minutes = 0;
        var id = 0;
        nrthis.activities.forEach((a) => {
            var m = (a.period === 'd') ? a.minutes : a.minutes / 7;
            a.id = id++;
            // a.kcal = util.myRound((bmrm * a.mets * m), 0);
            a.kcal = util.myRound((a.mets * 3.5 * stats.weight) / 200 * m, 0);
            nrthis.minutes += m;
            nrthis.netkcal += a.netkcal = util.myRound(a.kcal - (bmrm * m), 0);
        });
        this.netkcal = nrthis.netkcal;
    };
    delete_activity(id: number, s: xxstats) {
        this.activities.splice(id, 1);
        this.recalc_activities(s);
    }
    get_activities(stats: xxstats): oneActivity[] {
        this.recalc_activities(stats);
        return this.activities;
    };
    met_kcals_per_day(stats: xxstats): number {
        var total = 0;
        this.recalc_activities(stats);
        return this.netkcal;
    };
    daily_kcal(stats: xxstats): number {
        return this.met_kcals_per_day(stats);
    }
};

export default xxactivity;
