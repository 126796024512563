
import { defineComponent, inject } from "vue";
import * as util from "../modules/util";
import helpmodal from "./HelpModal.vue";
import macros from "./Macros.vue";
import activities from "./Activities.vue";
import metabolism from "./Metabolism.vue";
import summarytext from "@/components/SummaryText.vue";

import goals from "./Goals.vue";
import { xxstate } from "../store/index";

export default defineComponent({
  name: "bmr",
  components: { helpmodal, macros, activities, metabolism, goals, summarytext },
  data() {
    return {
      state: inject("state") as xxstate,
      iftin: {
        feet: 0,
        inches: 0,
      },
      iweight: 0,
      iweight_valid: true as boolean,
      itweight: 0,
      itweight_valid: true as boolean,
    };
  },
  mounted() {
    this.iweight = this.state.sane_weight(this.state.s.weight, true);
    this.itweight = this.state.sane_target_weight(this.state.g.weight, true);
    this.iftin = util.cm_to_ftin(this.state.s.height, 1);
  },
  computed: {
    bodyfat_range(): number[] {
      return this.state.s.gender === "m"
        ? util.create_number_array(5, 60)
        : util.create_number_array(10, 65);
    },
    cond_ttypes() : any[] {
      var ttypes = this.state.g.ttype_options();
      if (this.state.s.use_bodyfat) {
          return ttypes;
      } else {
        var filtered = ttypes.filter((x) => x.value !== 'bodyfat');
        console.log("Filtered array: " + filtered);
        return filtered;
      }
    }
  },
  methods: {
    change_use_bf(): void {
      console.log("CUBF:" + this.state.s.use_bodyfat + " " + this.state.g.ttype )
      if ((!this.state.s.use_bodyfat) && (this.state.g.ttype === 'bodyfat')) {
        this.state.g.ttype = 'deficit';
      }
    },
    check_iweight() {
      var minw = this.state.min_weight();
      var maxw = this.state.max_weight();
      if (this.iweight >= minw && this.iweight <= maxw) {
        this.iweight_valid = true;
        this.state.s.weight = this.state.weight_to_kg(this.iweight);
      } else {
        this.iweight_valid = false;
      }
    },
    check_iftin() {
      if (!this.state.x.metric) {
        this.state.s.height = util.myRound(util.ftin_to_cm(this.iftin), 0);
      }
    },
    check_itweight() {
      var minw = this.state.min_target_weight();
      var maxw = this.state.max_target_weight();
      console.log("Checking input weight: " + this.itweight);
      if (this.itweight >= minw && this.itweight <= maxw) {
        this.itweight_valid = true;
        this.state.g.weight = this.state.weight_to_kg(this.itweight);
      } else {
        this.itweight_valid = false;
      }
    },
    predict_bodyfat() {
      var pbf = Math.round(this.state.s.estimate_bodyfat());
      this.state.s.bodyfat =
        this.state.s.gender === "m"
          ? Math.min(Math.max(5, pbf), 60)
          : Math.min(Math.max(10, pbf), 65);
    },
    check_target_bodyfat(): void {
      var bf = this.state.g.bodyfat;
      var bfc =
        this.state.s.gender === "m"
          ? Math.min(Math.max(5, bf), 60)
          : Math.min(Math.max(10, bf), 65);
      if (bfc != bf) {
        this.state.g.bodyfat = bfc;
      }
    },
    check_current_bodyfat(): void {
      var bf = this.state.s.bodyfat;
      var bfc =
        this.state.s.gender === "m"
          ? Math.min(Math.max(5, bf), 60)
          : Math.min(Math.max(10, bf), 65);
      if (bfc != bf) {
        this.state.s.bodyfat = bfc;
      }
    },
    check_bodyfat(): void {
      this.check_current_bodyfat();
      this.check_target_bodyfat();
    },
  },
});
