
import { inject } from "vue";
import { xxstate } from "../store/index";

export default {
  name: "checkin",
  components: {},
  data() {
    return {
      state: inject("state") as xxstate,
      steps: "",
      kcal: "",
      weight: "",
      alevel: "",
    };
  },
  computed: {},
  methods: {},
};
