
import { defineComponent } from "vue";
import * as api from "@/modules/api";
import i18nstr from "@/modules/i18n";

export default defineComponent({
  name: "Logout",
  data() {
    return {
      apirc: undefined as api.api_res | undefined,
      logged_in: false,
      render: false,
    };
  },
  async created() {
    if (api.is_logged_in()) {
      this.apirc = (await api.do_api_call("logout", {}, true)) as api.api_res;
      this.logged_in = true;
    }
    await api.logout();
    this.render = true;
  },
  methods: {
    xlate(str: string): string {
      return i18nstr(str);
    }
  }
});
