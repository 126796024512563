import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-start" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("b", null, [
      _createElementVNode("p", null, " You will reach your target weight of " + _toDisplayString(_ctx.target_weight) + " in " + _toDisplayString(_ctx.state.goal_days()) + " days by consuming a daily average of " + _toDisplayString(_ctx.daily_allowance) + " kcal with the entered activity/exercise level. ", 1),
      _createElementVNode("p", null, " That is a weight difference of " + _toDisplayString(_ctx.weight_difference) + ". ", 1),
      (_ctx.state.has_accurate_bodyfat())
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Your body fat % will change from " + _toDisplayString(_ctx.state.s.bodyfat) + " to " + _toDisplayString(_ctx.state.g.target_bodyfat()) + ". ", 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, " Your BMI will change from " + _toDisplayString(_ctx.current_bmi) + " to " + _toDisplayString(_ctx.target_bmi) + ". ", 1)
    ])
  ]))
}