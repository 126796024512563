
import { defineComponent } from "vue";
import * as api from "@/modules/api";

export default defineComponent({
  name: "navbar",
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    curuserinfo() {
      let luser = api.cur_user();
      if (luser) {
        return luser.username || luser.firstname || luser.email;
      }
      return "<not logged in>";
    }
  },
  methods: {
  },
});
