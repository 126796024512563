<template>
  <div class="row">
    <div class="col-12">
      <div class="formula text-start">
        <h1>The formulas!</h1>

        The calculator supports three methods to calculate your basal metabolic
        rate (BMR).

        <h2>The Katch McArdle Formula</h2>

        <p>
          This method is considered the most accurate, if you know your current
          body fat level with a great deal of certainty and are relatively lean.
        </p>

        <p>
          It's recommended in order to use this formula, that you have a
          reliable way of measuring your body fat level. Acceptable methods
          includes Dexa scan, InBody or skin fold measurements done by someone
          experienced with using calipers. Most bathroom and fitness scales are
          grossly inaccurate and are known to be up to 10% off.
        </p>

        <p>
          If you're a man and have a body fat % less than 14-15% or a woman with
          a bodyfat percentage under below 19-20% and know your body fat %, use
          this formula for the best accuracy.
        </p>

        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Nulla aliquet enim
        tortor at auctor urna. Augue lacus viverra vitae congue. Tortor
        dignissim convallis aenean et tortor. Eu volutpat odio facilisis mauris
        sit amet massa vitae. Ornare suspendisse sed nisi lacus. Viverra
        suspendisse potenti nullam ac tortor vitae purus. Amet justo donec enim
        diam vulputate ut pharetra sit amet. Sed odio morbi quis commodo odio
        aenean sed. Curabitur gravida arcu ac tortor. Facilisis mauris sit amet
        massa vitae tortor condimentum. Mi proin sed libero enim sed faucibus
        turpis in eu. Nibh cras pulvinar mattis nunc sed blandit libero
        volutpat. Cras pulvinar mattis nunc sed blandit libero volutpat. In
        fermentum et sollicitudin ac orci phasellus egestas tellus. Diam
        maecenas ultricies mi eget mauris pharetra et ultrices neque.</p>
        
        <p id="fluff">Leo vel
        fringilla est ullamcorper eget nulla facilisi. Lorem dolor sed viverra
        ipsum nunc aliquet bibendum. Aliquam sem et tortor consequat id. Nisi
        quis eleifend quam adipiscing vitae proin sagittis. Lectus urna duis
        convallis convallis tellus id interdum. Senectus et netus et malesuada
        fames ac turpis. Vulputate dignissim suspendisse in est ante in. Varius
        morbi enim nunc faucibus a pellentesque sit amet porttitor. Leo urna
        molestie at elementum eu facilisis sed odio. Aenean sed adipiscing diam
        donec adipiscing tristique risus nec feugiat. Sed viverra ipsum nunc
        aliquet bibendum enim. Tempus imperdiet nulla malesuada pellentesque
        elit eget gravida cum sociis. Amet consectetur adipiscing elit duis
        tristique. Cursus in hac habitasse platea dictumst quisque sagittis
        purus. Est ante in nibh mauris. Risus sed vulputate odio ut enim blandit
        volutpat maecenas volutpat. Rhoncus dolor purus non enim praesent
        elementum facilisis leo. Odio pellentesque diam volutpat commodo sed
        egestas egestas. Ullamcorper dignissim cras tincidunt lobortis feugiat.
        Facilisis magna etiam tempor orci eu lobortis elementum nibh tellus.
        Dignissim cras tincidunt lobortis feugiat vivamus at augue eget arcu.
        Nisl tincidunt eget nullam non nisi est. Feugiat nibh sed pulvinar proin
        gravida hendrerit lectus a.</p>
        
        <p>Commodo elit at imperdiet dui accumsan sit
        amet. Congue nisi vitae suscipit tellus mauris a diam maecenas. Nullam
        eget felis eget nunc lobortis mattis aliquam faucibus purus. Faucibus
        vitae aliquet nec ullamcorper sit amet risus. In hac habitasse platea
        dictumst vestibulum rhoncus. Tellus cras adipiscing enim eu turpis.
        Scelerisque fermentum dui faucibus in. Pharetra et ultrices neque ornare
        aenean euismod. Feugiat scelerisque varius morbi enim nunc faucibus a
        pellentesque. Turpis massa tincidunt dui ut ornare lectus sit. Turpis
        tincidunt id aliquet risus. At in tellus integer feugiat scelerisque
        varius. Egestas congue quisque egestas diam in. Et tortor at risus
        viverra adipiscing at in tellus. Et egestas quis ipsum suspendisse. Eget
        nulla facilisi etiam dignissim diam quis enim lobortis. Vulputate enim
        nulla aliquet porttitor lacus luctus accumsan tortor posuere. Vitae
        auctor eu augue ut lectus. Nunc faucibus a pellentesque sit amet. Sit
        amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar. Amet
        nulla facilisi morbi tempus iaculis urna id. Amet nulla facilisi morbi
        tempus iaculis urna id volutpat. Sit amet nulla facilisi morbi. Quis
        commodo odio aenean sed adipiscing diam donec adipiscing tristique.
        Placerat orci nulla pellentesque dignissim enim sit amet. Sem integer
        vitae justo eget magna fermentum iaculis eu. Ultrices tincidunt arcu non
        sodales. Vel pharetra vel turpis nunc eget lorem dolor. Adipiscing at in
        tellus integer feugiat scelerisque varius morbi. Dui nunc mattis enim ut
        tellus elementum. Leo vel orci porta non pulvinar neque laoreet
        suspendisse. Dui id ornare arcu odio ut. Egestas congue quisque egestas
        diam in arcu. Ultrices tincidunt arcu non sodales neque sodales.
        Facilisis volutpat est velit egestas dui id. Enim neque volutpat ac
        tincidunt. Nibh sit amet commodo nulla. Pharetra et ultrices neque
        ornare aenean euismod elementum nisi. Vulputate ut pharetra sit amet.
        Risus quis varius quam quisque. Quisque egestas diam in arcu cursus
        euismod quis. Suspendisse ultrices gravida dictum fusce ut placerat
        orci. Nascetur ridiculus mus mauris vitae ultricies leo integer. Nisl
        rhoncus mattis rhoncus urna neque viverra justo nec ultrices. Egestas
        diam in arcu cursus euismod quis viverra nibh. Pellentesque elit eget
        gravida cum. Quam id leo in vitae turpis. Urna condimentum mattis
        pellentesque id nibh. Odio pellentesque diam volutpat commodo. Facilisi
        etiam dignissim diam quis. Sed faucibus turpis in eu mi bibendum neque
        egestas congue. Felis donec et odio pellentesque diam. Senectus et netus
        et malesuada fames ac. Nulla porttitor massa id neque aliquam vestibulum
        morbi blandit. Urna neque viverra justo nec ultrices dui sapien. Vel
        fringilla est ullamcorper eget nulla facilisi. Urna duis convallis
        convallis tellus id. Pharetra vel turpis nunc eget lorem dolor sed
        viverra ipsum. Vel risus commodo viverra maecenas accumsan lacus vel.
        Ultricies mi quis hendrerit dolor magna eget. Urna porttitor rhoncus
        dolor purus non enim praesent elementum. Nulla malesuada pellentesque
        elit eget gravida cum. Mattis enim ut tellus elementum sagittis vitae
        et. Feugiat vivamus at augue eget arcu dictum. Pharetra diam sit amet
        nisl suscipit adipiscing bibendum est ultricies. Volutpat odio facilisis
        mauris sit amet. Sed enim ut sem viverra aliquet eget sit. Et
        sollicitudin ac orci phasellus egestas tellus rutrum tellus. Pharetra
        massa massa ultricies mi quis hendrerit dolor magna. Accumsan tortor
        posuere ac ut consequat semper. Elit ut aliquam purus sit amet luctus
        venenatis. Maecenas pharetra convallis posuere morbi leo urna. Dui id
        ornare arcu odio ut. Fringilla phasellus faucibus scelerisque eleifend
        donec pretium vulputate.</p>

        <p>
          Placerat orci nulla pellentesque dignissim. Orci ac auctor augue
          mauris augue. Mi quis hendrerit dolor magna eget est lorem. Nunc sed
          blandit libero volutpat sed. Lorem dolor sed viverra ipsum nunc
          aliquet bibendum. Mauris ultrices eros in cursus. In cursus turpis
          massa tincidunt dui ut ornare lectus sit. Netus et malesuada fames ac
          turpis egestas integer eget aliquet. Volutpat est velit egestas dui id
          ornare arcu odio ut. Neque sodales ut etiam sit.
        </p>

        Sagittis orci a scelerisque purus semper eget. Sit amet porttitor eget
        dolor. Vivamus at augue eget arcu dictum varius duis. Interdum varius
        sit amet mattis vulputate enim. Tincidunt nunc pulvinar sapien et ligula
        ullamcorper malesuada proin. Nunc faucibus a pellentesque sit amet
        porttitor. Posuere urna nec tincidunt praesent semper feugiat nibh sed
        pulvinar. Diam in arcu cursus euismod quis viverra nibh cras. Orci
        sagittis eu volutpat odio facilisis mauris sit. Ut enim blandit volutpat
        maecenas volutpat blandit aliquam. Consequat interdum varius sit amet
        mattis vulputate. Purus sit amet luctus venenatis lectus magna.
        Condimentum lacinia quis vel eros donec ac odio. Fermentum posuere urna
        nec tincidunt praesent semper. Et ligula ullamcorper malesuada proin.
        Ultrices eros in cursus turpis massa tincidunt. Id diam maecenas
        ultricies mi eget mauris pharetra. Sed libero enim sed faucibus turpis
        in eu. Turpis egestas integer eget aliquet nibh. Velit ut tortor pretium
        viverra suspendisse potenti nullam ac tortor. Risus in hendrerit gravida
        rutrum quisque non tellus orci. Volutpat diam ut venenatis tellus in
        metus vulputate eu. Mattis vulputate enim nulla aliquet porttitor lacus
        luctus accumsan tortor. Nulla facilisi nullam vehicula ipsum a. Nunc
        consequat interdum varius sit amet mattis vulputate enim. Cras sed felis
        eget velit aliquet. Tristique sollicitudin nibh sit amet commodo nulla.
        Metus aliquam eleifend mi in nulla. Phasellus vestibulum lorem sed risus
        ultricies tristique. Amet consectetur adipiscing elit pellentesque
        habitant morbi. Arcu ac tortor dignissim convallis. Sociis natoque
        penatibus et magnis dis. Nisl nisi scelerisque eu ultrices vitae auctor
        eu augue. Ullamcorper sit amet risus nullam. Vulputate enim nulla
        aliquet porttitor lacus luctus. Odio morbi quis commodo odio aenean sed
        adipiscing diam.
      </div>
    </div>
  </div>
</template>
