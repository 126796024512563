
import { defineComponent } from "vue";

interface mymsg {
    [label: string] : string;
};

let texts: mymsg = {
    "formula": `<p><b>Mifflin-St Jeor Equation (MSJE)</b></br>
                This is probably the most accurate method for calculating your basal metabolic rate (BMR). If you have fairly
                normal body fat levels and are not athletic or obese, this should be your preferred choice. It usually comes
                within 10% of your actual measured BMR. Obese people are better off with the Revised Harris-Benedict Equation.
                </p>
                <p><b>Harris-Benedict Equation (HBE)</b><br/>
                A fairly accurate method for predicting your BMR, differing not much from MSJE. Like MSJE, it does not require
                you to know your current body fat level.</p>
                </p>
                <p><b>Revised Harris-Benedict Equation (RHBE)</b><br/>
                A revised version of HBE but a bit more accurate for leaner and athletic individuals.</p>
                <p><b>Katch-McArdle Formula (KMF)</b><br/>
                This method requires you to know your current body fat level with a high degree of accuracy. It is most
                accurate method for atheltic and lean individuals, typically less than 15% body fat for men and less than 20% for women.
                If you're not sure what your body fat level is, or it is outside of the mentioned range, you are better off using
                the the RHBE method.</p>`,
    "sex":      `You can be whatever you want but choose wisely. Pick the wrong one at your own peril. Don't blame the calculator if you choose unwisely 
                and one day find yourself looking like Jabba the Hutt or Bonejangles.`,
    "tdee":     "TDEE is something that your mom warned you about"
    
};

export default defineComponent({
    name: "helpmodal",
    props: {
        label: { type: String, required: true }
    },
    data() {
        return {
    }},
    computed: {
        label_id(): string {
            return "help" + this.label.toLowerCase();
        },
        label_id_h(): string {
            return "#help" + this.label.toLowerCase();
        },
        label_title(): string {
            return this.label;
        },
        label_text(): string {
            var id = this.label.toLowerCase();
            return texts[id] ? texts[id] : "";
        },
        has_label(): boolean {
            var id = this.label.toLowerCase();
            return texts[id] ? true : false;
        },
    }
});
