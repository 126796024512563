import * as api from "@/modules/api";
import { state } from "@/store/index";

interface stats_store {
    age: number,
    alevel: number,
    bodyfat: number,
    formula: "hbe" | "msje" | "rbhe",
    formula_bf: "kmf",
    gender: "m"|"f",
    height: number,
    use_bodyfat: boolean,
    weight: number,
};

interface api_data_tdee extends api.api_data {
    stats?: stats_store,
}
interface api_rc_tdee extends api.apirc {
    data: api_data_tdee,
}

export async function load_data() {
    var rc = (await api.do_api_call(
        "load",
        {
        },
        false
  )) as api_rc_tdee;

  if (rc.status === "OK") {
    console.log(JSON.stringify(rc.data, undefined, "  "));
    if (rc.data.stats) {
        if (rc.data.stats.age)
            state.s.age = rc.data.stats.age;
        if (rc.data.stats.alevel)
            state.s.alevel = rc.data.stats.alevel;
        if (rc.data.stats.bodyfat)
            state.s.bodyfat = rc.data.stats.bodyfat;
        if (rc.data.stats.formula_bf)
            state.s.formula_bf = rc.data.stats.formula_bf;
        if (rc.data.stats.gender)
            state.s.gender = rc.data.stats.gender;
        if (rc.data.stats.height)
            state.s.height = rc.data.stats.height;
        if (rc.data.stats.weight)
            state.s.weight = rc.data.stats.weight;
        if (rc.data.stats.use_bodyfat)
            state.s.use_bodyfat = rc.data.stats.use_bodyfat ? true : false;
      }
  }
}

// {"code":200,"data":{"stats":{"age":74,"alevel":1.375,"bodyfat":27,"formula":"msje","formula_bf":"kmf","gender":"m","height":176,"use_bodyfat":1,"weight":77}},"errors":{},"message":"SLUMBERGER","status":"OK"}

