
import { defineComponent, inject } from "vue";
import { xxstate } from "../store/index";

export default defineComponent({
  name: "summarytext",
  data() {
    return {
      state: inject("state") as xxstate,
    };
  },
  computed: {
      target_weight(): string {
        return this.state.display_weight(this.state.target_weight(), true, 1);
      },
      daily_allowance(): string {
        return this.state.get_daily_allowance().toFixed(0);
      },
      weight_difference(): string {
        return this.state.display_weight(-this.state.weight_lost(), true, 1);
      },
      current_bmi(): string {
        return this.state.s.get_bmi().toFixed(1);
      },
      target_bmi(): string {
        return this.state.g.target_bmi(this.state.s).toFixed(1);
      },
  },
  methods: {},
});
