type headers = {
    [id: string] : string;
}

type strvalhash = {
    [id: string] : string | number | boolean;
};

export type errorfields = {
    [id: string] : string;
}

export function is_empty(obj: Object) {
    return Object.keys(obj).length === 0;
}
export function num_keys(obj: Object) {
    return Object.keys(obj).length;
}

export interface user {
    user_id?: number,
    created?: number,
    username?: string,
    email?: string,
    firstname?: string;
    lastname?: string;
    mobile?: string;
    subscribe?: number;
    afflcode?: string;
}

export interface localuser {
    firstname: string;
    lastname: string;
    email: string;
    username: string;
};

interface tokendata {
    expires: number
}

export interface api_data {
    access?: string,
    refresh?: string,
    user?: user,
    data?: undefined,
    subjects?: errorfields | undefined,
}

export interface apirc {
    code: number,
    message: string|Error,
    data: api_data,
    status: string,
    errors: errorfields,
}

export class api_res {
    code = 0;
    message = "";
    data = {} as api_data;
    status = "";
    errors = {} as headers;

    constructor(apirc: Partial<apirc>) {
        this.code = apirc.code || 599;
        if (!apirc.message) {
            this.message = "[No Message From API]";
        } else {
            this.message = (typeof apirc.message === 'string') ? apirc.message : apirc.message.message;
        }
        this.status = apirc.status || "ERROR";
        this.data = apirc.data || {};
        this.errors = apirc.errors || {};
    }
    static toss(apirc: Partial<apirc>) : api_res {
        const e = new api_res(apirc);
        console.log("Throwing exception " + JSON.stringify(e, undefined, "  "));
        throw(e);
    } 
}

export async function redirect(redir: string) {
    return await window.location.replace(redir);
}

export function current_host() : string {
    let link = window.location.protocol + "//" + window.location.hostname;
    if (window.location.port && (window.location.port !== "80") && (window.location.port !== "443")) {
        link += ":" + window.location.port;
    }
    return link;
}

export function validate_email(email: string) : boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validate_password(password: string) : boolean {
    const r = /^.*(?=.{8,})(?=.*[\p{Ll}])(?=.*[\p{Lu}])(?=.*[\d]).*$/u;
    return password.match(r) ? true : false;
}

export function validate_nanoid(id: string) : boolean {
    const re = /^[a-zA-Z0-9_\-]{21}$/;
    return re.test(id);
}
export function validate_username(name: string) : boolean {
    const re = /^[\p{L}](?:[\p{L}\d]|[\-\s](?=[\p{L}\d])){3,38}$/ui;
    return re.test(name);
}
export function validate_name(name: string) : boolean {
    const re = /^[\p{L}](?:[\p{L}]|[\-\s](?=[\p{L}])){0,38}$/ui;
    return re.test(name);
}
export async function snooze(seconds: number, tag: string) {
    let p = new Promise((resolve, reject) => {
        setTimeout(() => resolve(true), seconds * 1000);
    });
    console.log(`${tag}: Snoozing ${seconds} seconds`);
    let result = await p;
    console.log(`${tag}: Snoozing done: ${result}`);
}

export function jwt_decrypt(token: string) : tokendata {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const td = JSON.parse(jsonPayload);
    // console.log(td);
    return td;
}

export function is_logged_in() : boolean{
    const refresh = localStorage.getItem("jwt_refresh");
    if (!refresh) {
        console.log("ILI1");
        return false;
    }
    const td = jwt_decrypt(refresh);
    if (!td.expires) {
        console.log("ILI2");
        return false;
    }
    const d1 = new Date(td.expires * 1000);
    const d2 = new Date(Date.now());
    if (d2 < d1) {
        console.log("ILI3");
        return true;
    }
    console.log("ILI4");
    return false;
}

export function cur_user(user?: user | null) : localuser | undefined {
    if (user) {
        let luser = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            username: user.username
        } as localuser;
        localStorage.setItem("cur_user", btoa(JSON.stringify(luser)));
            // Buffer.from(JSON.stringify(luser), 'utf8').toString('base64'));
        return luser;
    } else {
        const u = localStorage.getItem("cur_user");
        if (u) {
            let ud = atob(u); //Buffer.from(u, 'base64').toString('utf8');
            return JSON.parse(ud) as localuser;
        }
    }
    return undefined;
}

export async function logout() : Promise<void> {
    localStorage.removeItem("jwt_access");
    localStorage.removeItem("jwt_refresh");
    localStorage.removeItem("cur_user");
}

export function check_token(token: string|undefined) : tokendata|undefined {
    if (!token) {
        return undefined;
    }
    const td = jwt_decrypt(token);
    if (!td.expires) {
        return undefined;
    }
    const d1 = new Date(td.expires * 1000);
    const d2 = new Date(Date.now());

//   const ds1 = d1.toUTCString();
//    const ds2 = d2.toUTCString();
//    console.log("D1: " + d1 + " D2: " + d2);
//    console.log("EXP: " + ds1 + " - NOW: " + ds2);

    if (d2 >= d1) {
        return undefined;
    }
    return td;
}

function set_tokens(refresh: string, access: string) : void {
    if (refresh) {
        localStorage.setItem("jwt_refresh", refresh);
    }
    if (access) {
        localStorage.setItem("jwt_access", access);
    }
}

async function refresh_token() : Promise<string|undefined> {
    const refresh = await localStorage.getItem("jwt_refresh");
    if (!refresh) {
        api_res.toss({ status: "ERROR", code: 401, message: "No refresh token found"});
    }
    const r = await fetch(
        "/api/refresh", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ token: refresh, currenthost: current_host() })
    });
    const code = r.status;
    const rc = await r.json();
    if (r.status === 200) {
        if (!rc.data.access || !rc.data.refresh || !rc.data.user) {
            api_res.toss({status: "ERROR", code: code, message: "Refresh didn't return required tokens/user"});
        }
        set_tokens(rc.data.refresh, rc.data.access);
        cur_user(rc.data.user);
        return rc.data.access;
    } else {
        api_res.toss({status: "ERROR", code: code, message: "Remote said: " + rc.message});
    }
    return undefined; // never seen
}

export async function do_api_call(method: string, args: strvalhash, auth: boolean) : Promise<api_res|unknown> {
    let code = 500;
    const hdr: headers = {
        'Content-Type': 'application/json'
    };
    
    if (auth) {
        try {
            let access = localStorage.getItem("jwt_access") as string;
            if (!check_token(access)) {
                access = await refresh_token() as string;
            }
            hdr['Authorization'] = "Bearer " + access;
        } catch(e: unknown) {
            return e;
        }
    }

    try {
        args.currenthost = current_host();
        console.log(JSON.stringify(args));
        const r = await fetch(
            "/api/" + method, {
            method: "POST",
            headers: hdr,
            body: JSON.stringify(args)
        });
        code = r.status;
        if ((code !== 200) && (code !== 400) && (code !== 401)) {
            api_res.toss({status: "ERROR", code: code, message: "Remote said: " + code});
        }
        const rc = await r.json() as apirc;
        if (!rc.status || !rc.message) {
            return new api_res({status: "ERROR", code: code, message: "Bad JSON response (need status+message)"});
        }
        if (method === 'login' && code == 200 && rc.data.user) {
            const data = rc.data as api_data;
            if (data.refresh && data.access && data.user) {
                console.log("SETTING TOKENS AND USER");
                set_tokens(data.refresh, data.access);
                cur_user(data.user);
            } else {
                return new api_res({status: "ERROR", code: code, message: "Login didn't return required tokens/user"});
            }
        }
        return new api_res(rc);
    } catch(e: unknown) {
        if (e instanceof api_res) {
            return e;
        }
        return new api_res({status: "ERROR", code: code, message: e as string });
    }
}

export function login_redirect(url: string|undefined) {
    const purl = new URL("/u/login", current_host());
    purl.searchParams.append("redir", url ? url : window.location.href);
    window.location.replace(purl.href);
}

export async function whoami() : Promise<user|undefined> {
    const rc = await do_api_call("whoami", {}, true) as api_res;
    if (rc.status === "OK") {
        cur_user(rc.data.user);
        return rc.data.user;
    }
    return undefined;
}

export async function login_user(url: string) : Promise<user|undefined> {
    const user = await whoami();
    if (!user) {
        login_redirect(url);
    }
    return undefined;
}
