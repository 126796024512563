export const KCALKG = 7830 as number;
export const LBSPERKG = 2.204623 as number;
export const CMPERFOOT = 30.48;
export const CMPERINCH = 2.54;

type ftin = {
    feet: number,
    inches: number
};

export function myRound(num: number, precision: number): number {
    if (precision === 0) {
        return Math.round(+num);
    } else if (precision === 1) {
        return Math.round(+num * 10) / 10;
    } else {
        return Math.round(+num * 100) / 100;
    }
}

export function estimate_bodyfat(age: number, bmi: number, gender: "m" | "f"): number {
    var g: number = gender === "m" ? 0 : 1;

    // Body fat % = –44.988 + (0.503 * age) + (10.689 * gender) + (3.172 * BMI) – (0.026 * BMI2) + (0.181 * BMI * gender) – (0.02 * BMI * age) – (0.005 * BMI2 * gender) + (0.00021 * BMI2 * age)
    return (
        -44.988 +
        0.503 * age +
        10.689 * g +
        3.172 * bmi -
        0.026 * Math.pow(bmi, 2) +
        0.181 * bmi * g -
        0.02 * bmi * age -
        0.005 * Math.pow(bmi, 2) * g +
        0.00021 * Math.pow(bmi, 2) * age
    );
}

export function date_from_days(days: number): string {
    const d = new Date();
    var now = d.getTime();
    var nw = now + 24 * 60 * 60 * 1000 * days;
    d.setTime(nw);
    return (
        d.getDate().toString(10).padStart(2, "0") +
        "-" +
        (d.getMonth() + 1).toString(10).padStart(2, "0") +
        "-" +
        d.getFullYear()
    );
}

export function create_number_array(start: number, end: number): number[] {
    var range = [...Array(end - start + 1).keys()].map(x => x + start);
    return range;
}

export function kg_to_lbs(kg: number, round?: number): number {
    var lbs: number = kg * LBSPERKG;
    return (round != undefined) ? myRound(lbs, round) : lbs;
}

export function lbs_to_kg(lbs: number, round?: number): number {
    var kg: number = lbs * LBSPERKG;
    return (round != undefined) ? myRound(kg, round) : kg;
}

export function cm_to_ftin(cm: number, round?: number): ftin {
    var inches = cm / CMPERINCH;
    return {
        feet: Math.floor(inches / 12),
        inches: (round != undefined) ? myRound(inches % 12, round) : inches % 12,
    };
}

export function ftin_to_cm(ftin: ftin): number {
    return ftin.feet * CMPERFOOT +
        ftin.inches * CMPERINCH;
}

export function kcal_from_pcf(p: number, c: number, f: number): number {
    return (p * 4) + (c * 4) + (f * 9);
}

export function toLocalTime(time: number) {
    var d = new Date(time);
    var offset = (new Date().getTimezoneOffset() / 60) * -1;
    var n = new Date(d.getTime() + offset);
    return n;
};
