import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Formulas from '../views/Formulas.vue'
import Calc from '../views/Calc.vue'
import Macros from '../views/DietMacros.vue'
import ContactUs from '../views/u/ContactView.vue'
import Login from '../views/u/LoginView.vue'
import Logout from '../views/u/LogoutView.vue'
import Register from '../views/u/RegisterView.vue'
import Confirm from '../views/u/ConfirmView.vue'
import Settings from '../views/Settings.vue'
import Checkin from '../views/Checkin.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/calc',
    name: 'Calc',
    component: Calc
  },
  {
    path: '/about',
    name: 'About',
    component: About
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/formulas',
    name: 'Formulas',
    // component: () => import(/* webpackChunkName: "about" */ '../views/Formulas.vue')
    component: Formulas
  },
  {
    path: '/macros',
    name: 'Macros',
    // component: () => import(/* webpackChunkName: "about" */ '../views/Formulas.vue')
    component: Macros
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/u/contact',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/u/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/u/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/u/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/u/confirm',
    name: 'Confirm',
    component: Confirm
  },
  {
    path: '/checkin',
    name: 'Checkin',
    component: Checkin
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash }
    }
  },
  routes,
})

export default router;

